import React, { useState } from "react";
import { FaEye, FaEyeSlash, FaKey, FaMobileAlt, FaPhone } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../images/logo512.png";

const Newypin: React.FC = () => {
  const token = localStorage.getItem("token") || "";
  const tokenew = localStorage.getItem("tokenew") || "";

  const location = useLocation();
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [showOtp, setShowOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const { mobile }: any = location.state;

  const handleVerifyOtp = async (data: any) => {
    if (!token) {
      toast.error("Authorization token is missing. Please log in again.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        "http://development.smapidev.co.in/api/Api/create_pin",
        {
          mobile: mobile, // Ensure mobile is sent as a number
          pin: data.pin,
        },
        {
          headers: {
            token,
             "Content-Type": "application/x-www-form-urlencoded",
          'Cookie': 'ci_session=0b0000be09ab15b1746f67a94c05d0d6761be9f3'
          },
        }
      );

      const result = response.data;

      if (result.code === "100") {
        toast.success("PIN created successfully!");
        navigate("/security_pin");
      } else {
        toast.error(result.message || "An error occurred.");
      }
    } catch (error) {
      toast.error("Network error. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container ak mx-auto p-4 max-w-md  shadow-md login-primary text-white">
      <ToastContainer />
      <img src={logo} alt="Logo" className="flex mx-auto mb-4" />

      <div className="mb-4 relative">
        <label htmlFor="mobile" className="block text-left text-sm font-medium mb-1">
         
          Mobile Number
        </label>
        <div className="input-group">
            <span className="input-group-text ">
              <FaPhone />
            </span>
        <input
          type="text"
          id="mobile"
          name="mobile"
          defaultValue={mobile}
          disabled
          className="form-control w-full bg-white pl-30 px-4 py-2 border  focus:outline-none focus:border-blue-500 text-black"
        />
        </div>
      </div>

      <div className="mb-4 relative">
        <label htmlFor="pin" className="block text-left text-sm font-medium mb-1">
          <FaKey className="absolute top-9 left-3 text-black" />
          New PIN
        </label>
        <input
          type={showOtp ? "tel" : "password"}
          id="pin"
          {...register("pin", {
            required: "PIN is required",
            minLength: {
              value: 4,
              message: "PIN must be at least 4 digits long",
            },
            maxLength: {
              value: 4,
              message: "PIN cannot exceed 6 digits",
            },
            validate: (value) => !isNaN(Number(value)) || "PIN must be a number",
          })}
          className="w-full px-4 py-2 pl-30 border rounded-5 focus:outline-none focus:border-blue-500 text-black"
        />
        <button
          className="hideshow absolute top-9 right-2 text-black"
          type="button"
          onClick={() => setShowOtp(!showOtp)}
        >
          {showOtp ? <FaEyeSlash /> : <FaEye />}
        </button>
        {errors.pin?.message && (
          <span className="text-red-500">{errors.pin.message as string}</span>
        )}
      </div>

      <button
        type="button"
        onClick={handleSubmit(handleVerifyOtp)}
        className={`w-full mt-4 py-2 mb-2 rounded-5 ${loading ? "bg-gray-500" : "bg-blue-300 hover:bg-blue-600"} text-black focus:outline-none`}
        disabled={loading}
      >
        {loading ? "Processing..." : "Verify"}
      </button>
    </div>
  );
};

export default Newypin;
