import React from "react";
import logo from "../../images/logo512.png";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { FaPhone } from "react-icons/fa";

interface FormData {
  mobile: string;
}

const ForgotPin: React.FC = () => {
  const token = localStorage.getItem("token") || "";
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    try {
      const mobileNum = data.mobile;
      const formData = new URLSearchParams();
      formData.append("mobile", data.mobile);

      const response = await fetch(
        "https://development.smapidev.co.in/api/Api/forgot_pin",
        {
          method: "POST",
          body: formData,
          headers: {
            token,
          },
        }
      );
      const result = await response.json();
      if (response.ok) {
        toast.success(result?.message);
        navigate("/Verifypin", { state: { mobile: mobileNum } });
      } else {
        toast.error(result?.message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error during otp send:", error);
      toast.error("Error during OTP send");
    }
  };

  const gotoLoginPage = () => {
    navigate("/security_pin");
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Prevent leading zeros
    if (e.target.value.startsWith("0")) {
      e.target.value = e.target.value.slice(1);
    }
  };

  return (
    <div className="container ak mx-auto p-4 pt-5 max-w-md shadow-md text-left login-primary">
      <ToastContainer />
      <img src={logo} alt="Logo" className="flex mx-auto" />
      <h2 className="text-center text-2xl font-bold mt-4 mb-4 text-white">
        Reset Pin
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <label
            htmlFor="mobile"
            className="block text-white text-sm font-medium mb-1"
          >
            Mobile No
          </label>
          <div className="input-group">
            <span className="input-group-text">
              <FaPhone />
            </span>
            <input
              type="tel"
              id="mobile"
              placeholder="Enter number"
              maxLength={10}
              {...register("mobile", {
                required: true,
                pattern: /^[1-9]\d{9}$/, // Validates non-zero start and 10 digits
              })}
              className="form-control w-full px-4 py-2 border focus:outline-none focus:border-blue-500"
              onInput={handleInput}
            />
          </div>
          {errors.mobile && errors.mobile.type === "required" && (
            <span className="text-red-500">Mobile number is required</span>
          )}
          {errors.mobile && errors.mobile.type === "pattern" && (
            <span className="text-red-500">
              Mobile number must be a valid 10-digit number and not start with
              0
            </span>
          )}
        </div>

        <button
          type="submit"
          className="w-full bg-blue-300 mb-5 text-black py-2 rounded-5 hover:bg-blue-400 focus:outline-none focus:shadow-outline-blue"
        >
          Submit
        </button>

        <a href="#" className="mt-8 mb-2 text-white">
          Already have a Pin?
        </a>

        <button
          type="button"
          onClick={gotoLoginPage}
          className="w-full bg-yellow-500 text-black mt-3 py-2 rounded-5 hover:bg-blue-300 focus:outline-none focus:shadow-outline-blue"
        >
          Back
        </button>
      </form>
    </div>
  );
};

export default ForgotPin;
