import axios from 'axios';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { FaArrowLeft } from 'react-icons/fa';

const BettingInterface = () => {
  const token = localStorage.getItem("token") || "";
  const [jodiDigit, setJodiDigit] = useState('');
  const [leftDigit, setLeftDigit] = useState('');
  const [rightDigit, setRightDigit] = useState('');
  const [amount, setAmount] = useState('');
  const [jodiError, setJodiError] = useState('');
  const [bids, setBids] = useState<any[]>([]); // Array to store multiple bids
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const id = queryParams.get('id') || 'Unknown';
  const name = queryParams.get('name') || 'Unknown';
  const game = queryParams.get('gamee') || 'Unknown';  
  const navigate = useNavigate();
  
  const jodiSuggestions = Array.from({ length: 100 }, (_, index) =>
    index < 10 ? `0${index}` : `${index}`
  );

  const handleSubmit = async () => {
   

    const formData = new FormData();
    formData.append('game_bids', JSON.stringify({ bids }));

    try {
        const response = await axios.post('https://development.smapidev.co.in/api/Api/gali_disawar_place_bid', formData, {
            headers: {
                token
            },
        });

        toast.success('Bids submitted successfully!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        console.log('Response:', response.data);
        setBids([]); // Clear the bids after successful submission
    } catch (error) {
        toast.error('Failed to submit bids. Please try again.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        console.error('Error:', error);
    }
  };

  const handleJodiDigitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d{0,2}$/.test(value)) { // Allows only 2 digits
        setJodiDigit(value);
        if (value.length === 2) {
            setLeftDigit(value[0]); // First digit as left
            setRightDigit(value[1]); // Second digit as right
        }
        if (value.length !== 2) {
            setJodiError('Jodi Digit must be exactly 2 digits.');
        } else {
            setJodiError('');
        }
    }
  };

  const handleLeftDigitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d?$/.test(value)) { // Allows only 1 digit
        setLeftDigit(value);
    }
  };

  const handleRightDigitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d?$/.test(value)) { // Allows only 1 digit
        setRightDigit(value);
    }
  };

  const addBid = () => {
    // Validation check for required fields
    if (amount.trim() === '') {
        toast.error('Amount cannot be empty!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        return;
    }

     if (jodiDigit.trim() === '') {
        setJodiError(''); // Clear any previous error
    }

    // Validate jodiDigit only if it is not empty and ensure it is 2 digits
    if (jodiDigit.trim() !== '' && jodiDigit.length !== 2) {
        setJodiError('Jodi Digit must be exactly 2 digits.');
        return; // Stop execution if validation fails
    }

    // Check if amount is empty
    if (amount.trim() === '') {
        toast.error('Amount cannot be empty!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        return;
    }

    if (name === 'left_digit' && leftDigit.trim() === '') {
        toast.error('Left Digit cannot be empty!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        return;
    }

    if (name === 'right_digit' && rightDigit.trim() === '') {
        toast.error('Right Digit cannot be empty!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        return;
    }

    // Construct the bid object based on the digit type
    const bid = {
        game_id: id,
        game_type: name, 
        session: 'Open',
        bid_points: amount,
        left_digit: leftDigit,
        right_digit: rightDigit,
    };

    // Add the bid to the bids array
    setBids([...bids, bid]);

    // Clear the input fields
    setJodiDigit('');
    setLeftDigit('');
    setRightDigit('');
    setAmount('');
  };

  const handleSuggestionClick = (value: string) => {
    setJodiDigit(value);
    setJodiError(''); // Clear error if valid suggestion is selected
  };

  // Calculate total bid amount
  const totalBidAmount = bids.reduce((total, bid) => total + parseFloat(bid.bid_points), 0);

  return (
    <>
      <ToastContainer />
      <div className="navbar-main p-3 mb-6 text-left flex items-center text-white">
        <FaArrowLeft onClick={() => navigate(-1)} cursor="pointer" />
        <button className="ml-3 flex items-center font-bold">
          {name} ({game})
        </button>
      </div>
      <div className="container">
        <div className="card p-3">
          <div className="bgmatch card-header text-center text-white">
            <p>{new Date().toDateString()}</p>
          </div>
          <div className="card-body">
            {name === 'jodi_digit' && (
              <div className="form-group text-start mt-4">
                <label className="mb-2" htmlFor="jodiDigit">
                  Enter Jodi Digit
                </label>
                <input
                  type="text"
                  id="jodiDigit"
                  className="form-control"
                  value={jodiDigit}
                  onChange={handleJodiDigitChange}
                  placeholder="Enter Jodi Digit"
                  maxLength={2}
                />
                {jodiError && <div className="text-danger">{jodiError}</div>}

                {/* Suggestion Box */}
                {jodiDigit.length === 1 && (
                  <ul className="list-group mt-2">
                    {jodiSuggestions
                      .filter(suggestion => suggestion.startsWith(jodiDigit))
                      .map(suggestion => (
                        <li key={suggestion} className="list-group-item" onClick={() => handleSuggestionClick(suggestion)}>
                          {suggestion}
                        </li>
                      ))}
                  </ul>
                )}
              </div>
            )}

            {/* Left and Right Digit Fields */}
            {name === 'left_digit' && (
              <div className="form-group text-start mt-4">
                <label htmlFor="leftDigit" className="mb-2">
                  Left Digit
                </label>
                <input
                  type="text"
                  id="leftDigit"
                  className="form-control"
                  value={leftDigit}
                  onChange={handleLeftDigitChange}
                  placeholder="Enter Left Digit"
                  maxLength={1}
                />
              </div>
            )}

            {name === 'right_digit' && (
              <div className="form-group text-start mt-4">
                <label htmlFor="rightDigit" className="mb-2">
                  Right Digit
                </label>
                <input
                  type="text"
                  id="rightDigit"
                  className="form-control"
                  value={rightDigit}
                  onChange={handleRightDigitChange}
                  placeholder="Enter Right Digit"
                  maxLength={1}
                />
              </div>
            )}

            {/* Amount Input */}
            <div className="form-group text-start mt-4">
              <label className="mb-2" htmlFor="amount">
                Amount
              </label>
              <input
                type="text"
                className="form-control"
                id="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Enter Amount"
              />
            </div>

            {/* Add Bid Button */}
            <button
          className="bgmatch p-2 w-100 text-white mt-3"
          onClick={addBid}
        >
          Add Bid
        </button>

            {/* Bids Table */}
            {bids.length > 0 && (
          <div className="mt-4">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Game Type</th>
                  <th scope="col">Bid Points</th>
                  <th scope="col">Digit</th>
                 
                </tr>
              </thead>
              <tbody>
                {bids.map((bid, index) => (
                  <tr key={index}>
                    <td>{bid.game_type}</td>
                    <td>{bid.bid_points}</td>
                   <td>{bid.left_digit && bid.right_digit ? `${bid.left_digit}${bid.right_digit}` : bid.left_digit || bid.right_digit}</td>
                    {/* <td>{bid.left_digit && bid.right_digit ? ${bid.left_digit}${bid.right_digit} : bid.left_digit || bid.right_digit}</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="text-center">
              {/* <strong>Total Bid Amount: {totalBidAmount}</strong> */}
            </div>
          </div>
        )}

            {/* Submit Bids Button */}
            <button
          className="bgmatch p-2 w-100 text-white mt-3"
          onClick={handleSubmit}
        >
          Submit Bids
        </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BettingInterface;
