import axios from 'axios';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { FaArrowLeft } from 'react-icons/fa';

const StarBettingInterface = () => {
    const singleDigitSuggestions = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const leftDigitSuggestions = ['120', '123', '124', '125', '126', '127', '128', '129', '130',
    '134', '135', '136', '137', '138', '139', '140', '145', '146', '147', '148', '149', '150', '156', '157', '158', '159', '160',
    '167', '168', '169', '170', '178', '179', '180', '189', '190', '230', '234', '235', '236', '237', '238', '239', '240',
    '245', '246', '247', '248', '249', '250', '256', '257', '258', '259', '260', '267', '268', '269', '270', '278', '279',
    '280', '289', '290', '340', '345', '346', '347', '348', '349', '350', '356', '357', '358', '359', '360', '367', '368',
    '369', '370', '378', '379', '380', '389', '390', '450', '456', '457', '458', '459', '460', '467', '468', '469', '470',
    '478', '479', '480', '489', '490', '560', '567', '568', '569', '570', '578', '579', '580', '589', '590', '670', '678',
    '679', '680', '689', '690', '780', '789', '790', '890'];

    const doublePannaSuggestions = ['100', '110', '112', '113', '114', '115', '116', '117', '118', '119', '122', '133', '144',
    '155', '166', '177', '188', '199', '200', '220', '223', '224', '225', '226', '227', '228', '229', '233', '244', '255',
    '266', '277', '288', '299', '300', '330', '334', '335', '336', '337', '338', '339', '344', '355', '366', '377', '388',
    '399', '400', '440', '445', '446', '447', '448', '449', '455', '466', '477', '488', '499', '500', '550', '556', '557',
    '558', '559', '566', '577', '588', '599', '600', '660', '667', '668', '669', '677', '688', '699', '700', '770', '778',
    '779', '788', '799', '800', '880', '889', '899', '900', '990'];
    const triplePannaSuggestions = ['000', '111', '222', '333', '444', '555', '666', '777', '888', '999'];

  const token = localStorage.getItem("token") || "";
  const [isSuggestionsVisible, setIsSuggestionsVisible] = useState<boolean>(false); // State to control suggestion visibility
  const [suggestions, setSuggestions] = useState<string[]>([]); 
    const [doubblePanna, setDoubblePanna] = useState('');
    const [triplePanna, setTriplePanna] = useState('');
    const [leftDigit, setLeftDigit] = useState('');
    const [rightDigit, setRightDigit] = useState('');
    const [amount, setAmount] = useState('');
    const [jodiError, setJodiError] = useState('');
    const [bids, setBids] = useState<any[]>([]); // Array to store multiple bids
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
      const navigate = useNavigate()

    const id = queryParams.get('id') || 'Unknown';
    const name = queryParams.get('name') || 'Unknown';
    const game = queryParams.get('gamee') || 'Unknown';

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('game_bids', JSON.stringify({ bids }));

        console.log(Array.from(formData.entries())); // Check the form data in the console

        try {
            const response = await axios.post('https://development.smapidev.co.in/api/Api/starline_place_bid', formData, {
                headers: {
                   token
                },
            });

            toast.success('Bids submitted successfully!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            console.log('Response:', response.data);
            setBids([]); // Clear the bids after successful submission
        } catch (error) {
            toast.error('Failed to submit bids. Please try again.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            console.error('Error:', error);
        }
    };

    const addBid = () => {
        let digit = '';
        
        // Determine which digit type to check based on the game name
        if (name === 'doubble_panna') {
            digit = doubblePanna;
        } else if (name === 'triple_panna') {
            digit = triplePanna;
        } else if (name === 'single_panna') {
            digit = leftDigit;
        } else if (name === 'single_digit') {
            digit = rightDigit;
        }
    
        // Validation checks
        if (amount.trim() === '') {
            toast.error('Amount cannot be empty!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
    
        if (digit.trim() === '') {
            toast.error(`${name.replace('_', ' ')} cannot be empty!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
    
        // Check if the digit entered matches the suggested values
        let validDigit = false;
        if (name === 'single_digit') {
            validDigit = singleDigitSuggestions.includes(digit);
        } else if (name === 'single_panna') {
            validDigit = leftDigitSuggestions.includes(digit);
        } else if (name === 'doubble_panna') {
            validDigit = doublePannaSuggestions.includes(digit);
        } else if (name === 'triple_panna') {
            validDigit = triplePannaSuggestions.includes(digit);
        }
    
        // If the digit is not valid, show an error
        if (!validDigit) {
            toast.error('Invalid bet value! Please choose from the suggested options.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
    
        const bid = {
            game_id: id,
            game_type: name,
            session: 'Open',
            bid_points: amount,
            digit: digit,
            panna: '', // Assuming panna is not used in this context
        };
    
        // Add the bid to the bids array
        setBids([...bids, bid]);
        setDoubblePanna('');
        setTriplePanna('');
        setLeftDigit('');
        setRightDigit('');
        setAmount('');
    };
    
    
    const suggest = (inputValue: string, type: string) => {
        let filtered: string[] = [];
    
        // Filter based on inputValue
        if (type === 'single_digit') {
            filtered = singleDigitSuggestions.filter(digit => digit.startsWith(inputValue));
            setSuggestions(filtered);
            setIsSuggestionsVisible(true);
    
            // Hide suggestions if the input matches exactly
            if (filtered.includes(inputValue)) {
                setIsSuggestionsVisible(false);
            }
        } else if (type === 'single_panna') {
            filtered = leftDigitSuggestions.filter(digit => digit.startsWith(inputValue));
            setSuggestions(filtered);
            setIsSuggestionsVisible(true);
    
            // Hide suggestions if the input matches exactly
            if (filtered.includes(inputValue)) {
                setIsSuggestionsVisible(false);
            }
        } else if (type === 'double_panna') {
            filtered = doublePannaSuggestions.filter(panna => panna.startsWith(inputValue));
            setSuggestions(filtered);
            setIsSuggestionsVisible(true);
    
            // Hide suggestions if the input matches exactly
            if (filtered.includes(inputValue)) {
                setIsSuggestionsVisible(false);
            }
        } else if (type === 'triple_panna') {
            filtered = triplePannaSuggestions.filter(panna => panna.startsWith(inputValue));
            setSuggestions(filtered);
            setIsSuggestionsVisible(true);
    
            // Hide suggestions if the input matches exactly
            if (filtered.includes(inputValue)) {
                setIsSuggestionsVisible(false);
            }
        }
    };
    
    

    const handleSuggestionClick = (suggestion: string, type: string) => {
        if (type === 'single_digit') {
            setRightDigit(suggestion);
        } else if (type === 'single_panna') {
            setLeftDigit(suggestion);
        } else if (type === 'double_panna') {
            setDoubblePanna(suggestion);
        } else if (type === 'triple_panna') {
            setTriplePanna(suggestion);
        }
        setIsSuggestionsVisible(false); // Close suggestions on selection
    };
    
  

      
    return (
        <>
         <ToastContainer/>
          <div className="navbar-main p-3 mb-6 text-left flex items-center text-white">
                          <FaArrowLeft onClick={() => navigate(-1)} cursor="pointer" />
                          <button className="ml-3 flex items-center font-bold">
                            {" "}
                            {name} ({game})
                          </button>
                        </div>
            <div className="container">
                <div className="card p-3">
                <div className="bgmatch card-header text-center text-white">
                        {/* <h4>{name}</h4> */}
                        <p>{new Date().toDateString()}</p>
                    </div>
                    
                    <div className="card-body">
                        {name === 'doubble_panna' && (
                            <div className="form-group text-start mt-4">
                                <label className='mb-2' htmlFor="doubblePanna">Enter Doubble Panna</label>
                                <input
                                    type="text"
                                    id="doubblePanna"
                                    className="form-control"
                                    
                                    value={doubblePanna}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setDoubblePanna(value);  // Update state with the input value
                                
                                        // Only trigger the suggest function if the input is not empty
                                        if (value) {
                                            suggest(value, "double_panna");
                                        }
                                    }}
                                    
                                    placeholder="Enter Doubble Panna"
                                    maxLength={3}
                                />
                                {isSuggestionsVisible && suggestions.length > 0 && (
                                    <ul className="suggestions">
                                        {suggestions.map((suggestion, index) => (
                                            <li key={index} onClick={() => handleSuggestionClick(suggestion, 'double_panna')}>
                                                {suggestion}
                                            </li>
                                        ))}
                                    </ul>
                                )}

                                {jodiError && <div className="text-danger">{jodiError}</div>}
                            </div>
                        )}

                        {name === 'triple_panna' && (
                            <div className="form-group text-start mt-4">
                                <label className='mb-2' htmlFor="triplePanna">Enter Triple Panna</label>
                                <input
                                    type="text"
                                    id="triplePanna"
                                    className="form-control"
                                    value={triplePanna}
                                    // onChange={(e) => suggest(e.target.value, "triple_panna")}
                                    placeholder="Enter Triple Panna"
                                    maxLength={3}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setTriplePanna(value);  // Update state with the input value
                                
                                        // Only trigger the suggest function if the input is not empty
                                        if (value) {
                                            suggest(value, "triple_panna");
                                        }
                                    }}
                                />
                                 {isSuggestionsVisible && suggestions.length > 0 && (
                                    <ul className="suggestions">
                                        {suggestions.map((suggestion, index) => (
                                            <li key={index} onClick={() => handleSuggestionClick(suggestion, 'triple_panna')}>
                                                {suggestion}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                {jodiError && <div className="text-danger">{jodiError}</div>}
                            </div>
                        )}

                        {name === 'single_panna' && (
                            <div className="form-group text-start mt-4">
                                <label className='mb-2' htmlFor="leftDigit">Enter Single Panna</label>
                                <input
                                    type="text"
                                    id="leftDigit"
                                    className="form-control"
                                    value={leftDigit}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setLeftDigit(value);  // Update state with the input value
                                
                                        // Only trigger the suggest function if the input is not empty
                                        if (value) {
                                            suggest(value, "single_panna");
                                        }
                                    }}
                                    placeholder="Enter Single Panna"
                                    maxLength={3}
                                />
                                {isSuggestionsVisible && suggestions.length > 0 && (
                                    <ul className="suggestions">
                                        {suggestions.map((suggestion, index) => (
                                            <li key={index} onClick={() => handleSuggestionClick(suggestion, 'single_panna')}>
                                                {suggestion}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        )}

                        {name === 'single_digit' && (
                            <div className="form-group text-start mt-4">
                                <label className='mb-2' htmlFor="rightDigit">Enter Single Digit</label>
                                <input
                                    type="text"
                                    id="rightDigit"
                                    className="form-control"
                                    value={rightDigit}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setRightDigit(value);  // Update state with the input value
                                
                                        // Only trigger the suggest function if the input is not empty
                                        if (value) {
                                            suggest(value, "single_digit");
                                        }
                                    }}
                                    placeholder="Enter Single Digit"
                                    maxLength={1}
                                />
                                 {isSuggestionsVisible && suggestions.length > 0 && (
                                    <ul className="suggestions">
                                        {suggestions.map((suggestion, index) => (
                                            <li key={index} onClick={() => handleSuggestionClick(suggestion, 'single_digit')}>
                                                {suggestion}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        )}

                        <div className="form-group mt-3 text-start mt-4">
                            <label className='mb-2' htmlFor="amount">Enter Amount</label>
                            <input
                                type="number"
                                id="amount"
                                className="form-control"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                placeholder="Enter Amount"
                            />
                        </div>
                    </div>
                </div>

                <button
                    className="bgmatch p-2 w-100 text-white mt-3"
                    onClick={addBid}
                >
                    Add Bid
                </button>





                

                {/* Display added bids */}
                {bids.length > 0 && (

                <div className="mt-4">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Game Type</th>
                                <th scope="col">Digit</th>
                                <th scope="col">Bid Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bids.map((bid, index) => (
                                <tr key={index}>
                                    <td>{bid.game_type}</td>
                                    <td>{bid.digit}</td>
                                    <td>{bid.bid_points}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                )}


                {/* Display total bid amount and submit button in one row */}
                <div className="bgmatch mt-3 d-flex justify-content-center align-items-center text-white">
                    {/* <h5>Total Bid Amount: {totalBidAmount}</h5> */}
                    <button
                        className="bgmatch p-2 text-white"
                        onClick={handleSubmit}
                    >
                        Proceed
                    </button>
                </div>
            </div>
        </>
    );
};

export default StarBettingInterface;


