import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { NavBar2 } from '../NavBar2';
import left from "../../images/leftdigit.png";
import right from "../../images/rightdigit.png";
import jodi from "../../images/jodi-digit.svg";
import { FaArrowLeft } from 'react-icons/fa';

const DesawarGameplay: React.FC = () => {
   const Navigate=useNavigate()
   const param=useParams()

  const images = [
    {
      name: 'left_digit',
      src: left, // Corrected to directly reference the imported image
    },
    {
      name: 'right_digit',
      src: right, // Corrected to directly reference the imported image
    },
    {
      name: 'jodi_digit',
      src: jodi, // Corrected to directly reference the imported image
    },
  ];

  return (
    <>
      <div className="navbar-main p-3 mb-6 text-left flex items-center text-white">
                     <FaArrowLeft onClick={() => Navigate(-1)} cursor="pointer" />
                     <button className="ml-3 flex items-center font-bold">
                       {" "}
                       {param.name}
                     </button>
                   </div>
      <div className="container-fluid p-0 ">
        <div  className="row no-gutters justify-content-center ">
          {images.map((image, index) => (
            <div className="col-6 d-flex mt-3" key={index}>
              <Link to={`/betpage?id=${param.id}&name=${image.name}&gamee=${param.name}`}>
                <div className="image-container">
                  <img src={image.src} alt={`Desawar ${index + 1}`} className="img-fluid" />
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DesawarGameplay;
