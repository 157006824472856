import React, { useState } from "react";
import { FaEdit, FaRupeeSign } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { NavBar2 } from "./NavBar2";
import { MyDatePicker } from "./MyDatePicker";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

interface Bid {
  game_id?: number;
  game_type?: string;
  open_digit: string;
  close_panna: string;
  bid_points: string;
  session: string;
  open_panna?: string;
  close_digit?: string;
}

const HafSangam: React.FC<any> = () => {
  const CLOSING_PANNA = [100, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 122, 123, 124, 125, 126, 127, 128, 129, 130, 133, 134, 135, 136, 137, 138, 139, 140, 144, 145, 146, 147, 148, 149, 150, 155, 156, 157, 158, 159, 160, 166, 167, 168, 169, 170, 177, 178, 179, 180, 188, 189, 190, 199, 200, 220, 222, 223, 224, 225, 226, 227, 228, 229, 230, 233, 234, 235, 236, 237, 238, 239, 240, 244, 245, 246, 247, 248, 249, 250, 255, 256, 257, 258, 259, 260, 266, 267, 268, 269, 270, 277, 278, 279, 280, 288, 289, 290, 299, 300, 330, 333, 334, 335, 336, 337, 338, 339, 340, 344, 345, 346, 347, 348, 349, 350, 355, 356, 357, 358, 359, 360, 366, 367, 368, 369, 370, 377, 378, 379, 380, 388, 389, 390, 399, 400, 440, 444, 445, 446, 447, 448, 449, 450, 455, 456, 457, 458, 459, 460, 466, 467, 468, 469, 470, 477, 478, 479, 480, 488, 489, 490, 499, 500, 550, 555, 556, 557, 558, 559, 560, 566, 567, 568, 569, 570, 577, 578, 579, 580, 588, 589, 590, 599, 600, 660, 666, 667, 668, 669, 670, 677, 678, 679, 680, 688, 689, 690, 699, 700, 770, 777, 778, 779, 780, 788, 789, 790, 799, 800, 880, 888, 889, 890, 899, 900, 990, 999];
  const digit = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  const location = useLocation();
  const token = localStorage.getItem("token") || "";

  const [bids, setBids] = useState<Bid[]>([]);
  const [suggestedOpenPanna, setSuggestedOpenPanna] = useState<number[]>([]);
  const [suggestedClosePanna, setSuggestedClosePanna] = useState<number[]>([]);
  const [suggestedOpenDigit, setSuggestedOpenDigit] = useState<number[]>([]);
  const [suggestedCloseDigit, setSuggestedCloseDigit] = useState<number[]>([]);

  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm<Bid>();
  const [session, setSession] = useState<'open' | 'close'>('open'); // Track session state

  const onSubmit = (data: Bid) => {
    const defaultValues: Partial<Bid> = {
      game_id: location?.state?.id,
      game_type: "half_sangam"
    };



    const bidData = { ...defaultValues, ...data, session }; // Ensure session is included
    setBids([...bids, bidData]);
    reset();
  };

  const handleFormSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const submitBid = async () => {
    if (bids.length === 0) {
      toast.error("Please add bids before submitting.");
      return;
    }

    const formData = new FormData();
    const modifiedBids = bids.map(bid => ({
      ...bid,
      open_panna: bid.open_panna || '',
      close_digit: bid.close_digit || '',
      open_digit: bid.open_digit || '',
      close_panna: bid.close_panna || ''
    }));

    formData.append("game_bids", JSON.stringify({ bids: modifiedBids }));

    try {
      const response = await axios.post(
        "https://development.smapidev.co.in/api/Api/place_bid",
        formData,
        {
          headers: {
            token,
            "Content-Type": "application/x-www-form-urlencoded",
            Cookie: "ci_session=0b0000be09ab15b1746f67a94c05d0d6761be9f3",
          },
        }
      );

      if (response.data.status === "success") {
        toast.success("Bids submitted successfully!");
        setBids([]); // Clear bids after submission
      } else {
        toast.error(response.data.message || "Failed to submit bids.");
      }
    } catch (error) {
      toast.error("An error occurred while submitting bids. Please try again.");
    }
  };


  const suggestPannas = (inputValue: string, type: string) => {

    if (type === 'open_digit') {
      const filteredPannas = digit.filter(panna => panna.toString().startsWith(inputValue));
      setSuggestedOpenDigit(filteredPannas);
    } else if (type === 'close_panna') {
      const filteredPannas = CLOSING_PANNA.filter(panna => panna.toString().startsWith(inputValue));
      setSuggestedClosePanna(filteredPannas);
    } else if (type === 'open_panna') {
      const filteredPannas = CLOSING_PANNA.filter(panna => panna.toString().startsWith(inputValue));
      setSuggestedOpenPanna(filteredPannas);
    } else if (type === 'close_digit') {
      const filteredPannas = digit.filter(panna => panna.toString().startsWith(inputValue));
      setSuggestedCloseDigit(filteredPannas);
    }
  };


  type PannaType = "game_id" | "game_type" | "open_digit" | "close_panna" | "bid_points" | "session" | "open_panna" | "close_digit";

  const setOpenPanna = (panna: number, type: PannaType) => {
    setValue(type, panna.toString());
    if (type === 'open_digit') {
      setSuggestedOpenDigit([]);
    } else if (type === 'close_panna') {
      setSuggestedClosePanna([]);
    } else if (type === 'open_panna') {
      setSuggestedOpenPanna([]);
    } else {
      setSuggestedCloseDigit([]);
    }
  };



    // Custom validation function to check if open panna exists
    const validateOpenPanna = (value: string) => {
      const isValid = CLOSING_PANNA.includes(parseInt(value));
      return isValid || "Enter valid panna.";
    };

  return (
    <div className="bg-gray-100 rounded-lg">
      <ToastContainer />
      <NavBar2 isHalfSangam={location?.state?.isHalfSangam} />
      <div className="container shadow-md w-100 bg-white p-3 rounded-md text-left">
        <div className="flex">
          <MyDatePicker />
        </div>

        {/* Session Selection */}
        <div className="container text-left">
          <h2 className="choose_session font-bold mb-4">Choose Session</h2>
          <div className="open flex justify-between">
            <label>
              <input
                type="radio"
                checked={session === 'open'}
                onChange={() => setSession('open')}
              /> Open
            </label>
            <label>
              <input
                type="radio"
                checked={session === 'close'}
                onChange={() => setSession('close')}
              /> Close
            </label>
          </div>
          {errors.session && <span className="text-red-500">Please select a session</span>}
        </div>

        {/* Render form based on session */}
        {session === 'open' && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="panna-new text-left mt-4">
              <label htmlFor="digit">Open Digit</label>
              <div className="input-group">
                <span className="input-group-text"><FaEdit /></span>
                <input
                  type="text"
                  id="open_digit"
                  {...register("open_digit", { required: true })}
                  onChange={(e) => suggestPannas(e.target.value, "open_digit")}
                  maxLength={1}
                  placeholder="Enter open digit"
                  className="form-control"
                />
              </div>
              {errors.open_digit && <span className="text-red-500">{errors.open_digit.message}</span>}
              {suggestedOpenDigit.length > 0 && (
                <ul className="suggestions">
                  {suggestedOpenDigit.map(panna => (
                    <li key={panna} onClick={() => setOpenPanna(panna, 'open_digit')}>
                      {panna}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="panna-new text-left mt-4">
              <label htmlFor="digit">Close Panna</label>
              <div className="input-group">
                <span className="input-group-text"><FaEdit /></span>

                <input
                  type="text"
                  id="close_panna"
                  className="form-control"

                  {...register("close_panna", { required: true,validate: validateOpenPanna  })}
                  onChange={(e) => {
                    const value = e.target.value;

                    // Check if the value is valid and filter suggestions
                    if (value === '' || CLOSING_PANNA.some((panna) => panna.toString().startsWith(value))) {
                      suggestPannas(value, "close_panna");
                      setValue("close_panna", value); // Update form value
                    } else {
                      toast.error("Invalid Panna. Please select from the suggestions.");
                    }
                  }}
                  placeholder="Enter close panna"
                />
              </div>
              {errors.close_panna && <span className="text-red-500">{errors.close_panna.message}</span>}
              {suggestedClosePanna.length > 0 && (
                <ul className="suggestions">
                  {suggestedClosePanna.map(panna => (
                    <li key={panna} onClick={() => setOpenPanna(panna, 'close_panna')}>
                      {panna}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="panna-new text-left mt-4">
              <label htmlFor="points">Points</label>
              <div className="input-group">
                <span className="input-group-text"><FaRupeeSign /></span>
                <input
                  type="text"
                  className="form-control"
                  {...register("bid_points", { required: true, pattern: /^[0-9]+$/ })}
                  placeholder="Enter Points"
                />
              </div>
              {errors.bid_points && <span className="text-red-500">Please enter valid points</span>}
            </div>
            <div className="Proceed mt-6">
              <button type="submit" className="btn-proceed bgmatch w-100 text-white py-2 px-4 rounded-lg">Add Bid</button>
            </div>
          </form>
        )}

        {/* Form for 'close' session */}
        {session === 'close' && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="panna-new text-left mt-4">
              <label htmlFor="digit">Close Digit</label>
              <div className="input-group">
                <span className="input-group-text"><FaEdit /></span>
                <input
                  type="text"
                  id="close_digit"
                  className="form-control"
                  {...register("close_digit", { required: true })}
                  onChange={(e) => suggestPannas(e.target.value, "close_digit")}
                  maxLength={1}
                  placeholder="Enter close digit"
                />
              </div>
              {errors.close_digit && <span className="text-red-500">{errors.close_digit.message}</span>}
              {suggestedCloseDigit.length > 0 && (
                <ul className="suggestions">
                  {suggestedCloseDigit.map(panna => (
                    <li key={panna} onClick={() => setOpenPanna(panna, 'close_digit')}>
                      {panna}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="panna-new text-left mt-4">
              <label htmlFor="digit">Open Panna</label>
              <div className="input-group">
                <span className="input-group-text"><FaEdit /></span>
                <input
                  type="text"
                  className="form-control"

                  maxLength={3}

                  id="open_panna"

                  {...register("open_panna", { required: true })}
                  onChange={(e) => {
                    const value = e.target.value;
                
                    // Check if the value is valid and filter suggestions
                    if (value === '' || CLOSING_PANNA.some((panna) => panna.toString().startsWith(value))) {
                      suggestPannas(value, "open_panna");
                      setValue("open_panna", value); // Update form value
                    } else {
                      toast.error("Invalid Panna. Please select from the suggestions.");
                    }
                  }}
                  placeholder="Enter Open Panna"
                />
              </div>
              {errors.open_panna && <span className="text-red-500">{errors.open_panna.message}</span>}
              {suggestedOpenPanna.length > 0 && (
                <ul className="suggestions">
                  {suggestedOpenPanna.map(panna => (
                    <li key={panna} onClick={() => setOpenPanna(panna, 'open_panna')}>
                      {panna}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="panna-new text-left mt-4">
              <label htmlFor="points">Points</label>
              <div className="input-group">
                <span className="input-group-text"><FaRupeeSign /></span>
                <input
                  type="text"
                  className="form-control"
                  {...register("bid_points", { required: true, pattern: /^[0-9]+$/ })}
                  placeholder="Enter Points"
                />
              </div>
              {errors.bid_points && <span className="text-red-500">Please enter valid points</span>}
            </div>
            <div className="Proceed mt-6">
              <button type="submit" className="btn-proceed bgmatch w-100 text-white py-2 px-4 rounded-lg">Add Bid</button>
            </div>
          </form>
        )}


        {/* Display bids in a table */}
        <div className="mt-6">
          <h2 className="font-bold mb-4">Bids</h2>
          <table className="table">
            <thead>
              <tr>
                <th>Session</th>
                <th> Digit</th>
                <th>Panna</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              {bids.map((bid, index) => (
                <tr key={index}>
                  <td>{bid.session}</td>
                  <td>{bid.open_digit || bid.close_digit}</td>
                  <td>{bid.close_panna || bid.open_panna}</td>
                  <td>{bid.bid_points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Submit Button */}
        <div className="mt-4">
          <button className="btn-submit w-100 bgmatch text-white py-2 px-4 rounded-lg" onClick={submitBid}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default HafSangam;
