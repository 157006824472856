import { useEffect, useState } from "react";
import { FaCoins, FaMobileAlt, FaGoogle, FaPaypal } from "react-icons/fa";
import { NavBar2 } from "./NavBar2";
import { useNavigate } from "react-router-dom";
import j from "../images/j.png"
import k from "../images/k.png"
import l from "../images/l.png"
import b from "../images/banka.png"

const PaymentMethods = () => {
  const token = localStorage.getItem("token") || "";

  const navigate = useNavigate();

  return (
    <div className="px-15">
      <NavBar2 isWithdraw={true} />

      {/* <div className="custom-color text-white  shadow-md p-2 mb-3 w-100">
        <div className="flex items-center justify-around mb-2">
          <p className="text-lg font-semibold">Payment Methods</p>
        </div>
      </div> */}
      <div className="container flex justify-evenly">
        <button
          className="flex flex-col items-center space-y-1 border border-blue-900 text-blue-500 px-4 py-2 rounded-md pl-4 w-1/2 mr-10"
          onClick={() => navigate("/bank-details")}
        >
         <img src={b} alt="" width={60} /> <span>Bank</span>
        </button>
        <button
          className="flex flex-col items-center space-y-1 border border-green-500 text-green-500 px-4 py-2 rounded-md pl-4 w-1/2"
          onClick={() => navigate("/phonepe")}
        >
          <img src={k} alt="" width={60} /> <span>Phone Pay</span>
        </button>
      </div>
      <div className="container flex justify-evenly mt-10">
        <button
          className="flex flex-col items-center space-y-1 border border-yellow-500 text-yellow-500 px-4 py-2 rounded-md pl-4 w-1/2 mr-10"
          onClick={() => navigate("/gpay")}
        >
          <img src={j} alt="" width={60} /> <span>Google Pay</span>
        </button>
        <button
          className="flex flex-col items-center space-y-1 border border-indigo-500 text-indigo-500 px-4 py-2 rounded-md pl-4 w-1/2"
          onClick={() => navigate("/paytm")}
        >
        <img src={l} alt="" width={60} /> <span>Paytm</span>
        </button>
      </div>
    </div>
  );
};

export default PaymentMethods;
