import React, { useState } from "react";
import { FaEdit, FaRupeeSign } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { NavBar2 } from "./NavBar2";
import { MyDatePicker } from "./MyDatePicker";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

interface SingleDigitProps {
  isDoublePanna?: boolean;
}

interface Bid {
  game_id?: number;
  game_type?: string;
  open_digit: string;
  bid_points: string;
  session: string;
  open_panna?: string; 
  close_panna?: string; 
  close_digit?: string; 
}

const TripplePanna: React.FC<any> = () => {
  const TRIPPLE_PANNA = ["000", "111", "222", "333", "444", "555", "666", "777", "888", "999"];

  const location = useLocation();
  const token = localStorage.getItem("token") || "";
  const [bids, setBids] = useState<Bid[]>([]);
  const { register, handleSubmit, formState: { errors }, reset, getValues, setValue } = useForm<Bid>();
  const [suggestedPannas, setSuggestedPannas] = useState<string[]>([]);

  const onSubmit = (data: Bid) => {
    const defaultValues: Partial<Bid> = {
      game_id: location?.state?.id,
      game_type: "triple_panna",
    };

    const bidData = { ...defaultValues, ...data };
    setBids([...bids, bidData]);
    reset();
  };

  const submitBid = async () => {
    if (bids.length === 0) {
      toast.error("Please add bids before submitting.");
      return;
    }

    const formData = new FormData();
    const modifiedBids = bids.map((bid) => ({
      ...bid,
      open_panna: bid.open_panna ?? "default_value",
      close_panna: bid.close_panna ?? "default_value",
      close_digit: bid.close_digit ?? "default_value",
    }));

    formData.append("game_bids", JSON.stringify({ bids: modifiedBids }));

    try {
      const response = await axios.post("https://development.smapidev.co.in/api/Api/place_bid", formData, {
        headers: {
          token,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (response.data.status === "success") {
        toast.success("Bids submitted successfully!");
        setBids([]);
      } else {
        toast.error(response.data.message || "Failed to submit bids.");
      }
    } catch (error) {
      toast.error("An error occurred while submitting bids. Please try again.");
    }
  };

  const suggestPannas = (inputValue: string) => {
    if (inputValue.length === 0) {
      setSuggestedPannas([]);
      return;
    }

    const filteredPannas = TRIPPLE_PANNA.filter((panna) =>
      panna.startsWith(inputValue)
    );
    setSuggestedPannas(filteredPannas);
  };

  const setOpenPanna = (panna: string, currentSession: string) => {
    setValue("open_digit", panna);
    setSuggestedPannas([]);
  };

  return (
    <div>
      <ToastContainer />
      <NavBar2 isTripplePanna={location?.state?.isTripplePanna} />
      <div className="container shadow-md w-100 bg-white p-3 rounded-md text-left">
        <div>
          <MyDatePicker />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container text-left">
            <h2 className="choose_session font-bold mb-4">Choose Session</h2>
            <div className="open flex justify-between">
              <label>
                <input type="radio" {...register("session", { required: true })} value="open" /> Open
              </label>
              <label>
                <input type="radio" defaultChecked={true} {...register("session", { required: true })} value="close" /> Close
              </label>
            </div>
            {errors.session && <span className="text-red-500">Please select a session</span>}
          </div>
          <div className="panna-new text-left mt-4">
            <label htmlFor="digit">Triple Panna</label>
            <div className="input-group">
              <span className="input-group-text"><FaEdit /></span>
              <input
                type="text"
                className="form-control"
                {...register("open_digit")}
                maxLength={3}
                placeholder="Enter Triple Panna"
                onChange={(e) => suggestPannas(e.target.value)}
              />
            </div>
            {errors.open_digit && <span className="text-red-500">Please enter a valid triple panna</span>}
            {suggestedPannas.length > 0 && (
              <ul>
                {suggestedPannas.map((panna, index) => (
                  <li
                    className="cursor-pointer"
                    key={index}
                    onClick={() => setOpenPanna(panna, getValues().session)}
                  >
                    {panna}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="panna-new text-left mt-4">
            <label htmlFor="points">Points</label>
            <div className="input-group">
              <span className="input-group-text"><FaRupeeSign /></span>
              <input
                type="text"
                className="form-control"
                {...register("bid_points")}
                placeholder="Enter Points"
              />
            </div>
            {errors.bid_points && <span className="text-red-500">Please enter valid points</span>}
          </div>
          <div className="Proceed mt-6">
            <button type="submit" className="btn-proceed bgmatch w-100 text-white py-2 px-4 rounded-lg">
              Add Bid
            </button>
          </div>
        </form>

        <div className="mt-6">
          <h2 className="font-bold mb-4">Bids</h2>
          <table className="table">
            <thead>
              <tr>
                <th>Session</th>
                <th>Digit</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              {bids.map((bid, index) => (
                <tr key={index}>
                  <td>{bid.session}</td>
                  <td>{bid.open_digit}</td>
                  <td>{bid.bid_points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-4">
          <button
            className="btn-submit bgmatch text-white py-2 w-100 px-4 rounded-lg"
            onClick={submitBid}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default TripplePanna;
