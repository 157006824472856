import React, { useEffect, useState } from "react";
import { NavBar2 } from "../NavBar2";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { log } from "console";

interface WinHistoryProps {
  data: {
    game_id: string;
    session: string;
    game_type: string;
    open_digit: string;
    close_digit: string;
    open_panna: string;
    close_panna: string;
    win_points: string;
    bid_points: string;
    won_at: string;
    game_name: string;
  }[];
}

export const WinHistory: React.FC = () => {
  const token = localStorage.getItem("token") || "";
  const todayDate = new Date().toISOString().split("T")[0];
  const [fromDate, setFromDate] = useState<string>(todayDate);
  const [toDate, setToDate] = useState<string>(todayDate);
  const [data, setData] = useState<WinHistoryProps["data"]>([]);



  console.log(data)
  const handleFromDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToDate(event.target.value);
  };

  const handleSubmit = async () => {
    console.log("From Date:", fromDate);
    console.log("To Date:", toDate);
  
    try {
      // Set the start of the day (00:00) for fromDate
      const fromDateWithTime = new Date(fromDate);
      fromDateWithTime.setHours(0, 0, 0, 0);
      
      // Set the end of the day (23:59) for toDate
      const toDateWithTime = new Date(toDate);
      toDateWithTime.setHours(23, 59, 59, 999);
  
      const formData = new URLSearchParams();
      formData.append("from_date", fromDateWithTime.toISOString());
      formData.append("to_date", toDateWithTime.toISOString());
  
      let apiUrl = "https://development.smapidev.co.in/api/Api/win_history";
  
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          token,
          "Content-Type": "application/x-www-form-urlencoded",
          Cookie: "ci_session=d2a8bfc834befa449f25ec1a4d1e4de08c515354",
        },
        body: formData,
      });
  
      response
        .json()
        .then((data: any) => {
          if (data?.code === "100") {
            setData(data.data);
            console.log(data.data);
          } else {
            toast.error(data?.message);
          }
        })
        .catch((error: any) => {
          toast.error("An error occurred");
        });
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <>
      <NavBar2 isWinHistory={true} />
      <div className="container mx-auto p-2 rounded-lg text-white mt-4">
        <ToastContainer />
        <div className="login-primary px-4 py-4 rounded-4">
          <div className="flex justify-between items-center space-x-4">
            <h1 className="text-white">From Date</h1>
            <h1 className="text-white">To Date</h1>
          </div>

          <div className="flex justify-between">
            <input
              type="date"
              value={fromDate}
              onChange={handleFromDateChange}
              className="rounded-lg px-1 py-2 pl-2 mt-2 mb-4 bg-gray-200 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
            <input
              type="date"
              value={toDate}
              onChange={handleToDateChange}
              max={todayDate}
              className="rounded-lg px-1 py-2 pl-2 mt-2 mb-4 bg-gray-200 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-400 ml-2"
            />
          </div>

          <button
            onClick={handleSubmit}
            className="rounded mt-3 bg-blue-300 px-5 py-2 text-black"
          >
            Submit
          </button>
        </div>
      </div>


 


      <div className="container mt-4">
        {data.length > 0 ? (
          data.map((item, index) => (
            

              <div
  className="card"
  style={{
    border: "1px solid #d1d9e6",
    backgroundColor: "#f0f8ff",
    borderRadius: "8px",
    maxWidth: "96%",
    margin: "auto",
    padding: "10px",
  }}
>
  <div className="card-body p-2">
    {/* Game name and type */}
    <h6
      className="mb-2"
      style={{
        fontSize: "16px",
        fontWeight: "bold",
        textAlign: "start",
      }}
    >
      {item.game_name} ({item.game_type})
    </h6>

    {/* Won points */}
    <p
      style={{
        color: "green",
        fontWeight: "bold",
        fontSize: "18px",
        textAlign: "center",
        marginBottom: "10px",
      }}
    >
     Won Points: {item.win_points}
    </p>

    {/* Bid points and FS */}
    <div className="d-flex justify-content-between">
      <span style={{ fontSize: "14px" }}>
        <strong>Bid Points:</strong> {item.bid_points}
      </span>
      <span style={{ fontSize: "14px" }}>
      <strong>
      {item.game_type === 'full_sangam' && 'FS:'}
      {item.game_type === 'half_sangam' && 'HS:'}
      {item.game_type === 'double_panna' && 'Panna:'}
      {item.game_type !== 'double_panna' && item.game_type !== 'full_sangam' && item.game_type !== 'half_sangam' && 'Digits:'}
    </strong>
  {item.open_panna && `${item.open_panna}  `} 
  {item.close_panna && `${item.close_panna} `} 
  {item.close_digit && `${item.close_digit}  `} 
  {item.open_digit && `${item.open_digit}`}
</span>

    </div>

    {/* Date and time */}
    <p
      className="mt-2"
      style={{
        fontSize: "12px",
        color: "#6c757d",
        textAlign: "start",
      }}
    >
      {new Date(item.won_at).toLocaleString()}
    </p>
  </div>
</div>
           
          ))
        ) : (
          <div className="text-center mt-4">
          <img
            src="https://img.freepik.com/premium-vector/no-data-concept-illustration_634196-28497.jpg?semt=ais_hybrid" // Replace with your image path
            alt="No Data"
            style={{ width: "150px", height: "150px", margin: "auto" }}
          />
          <p style={{ color: "#6c757d", marginTop: "10px" }}>No data available</p>
        </div>
      )}
      </div>
    </>
  );
};

export default WinHistory;
