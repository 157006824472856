import React from "react";
import "./whatsaap.css";
import whatsappIcon from "../../images/support.png"; // Add a WhatsApp icon in the `assets` folder

const FloatingButton = () => {

  return (
    <div className="floating-button"  onClick={() => {
        window.location.href = `https://wa.me/916377580612`;
      }}>
      <img src={whatsappIcon} alt="WhatsApp" />
    </div>
  );
};

export default FloatingButton;
