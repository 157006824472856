import React, { useState } from "react";
import { FaEdit, FaRupeeSign } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { NavBar2 } from "./NavBar2";
import { MyDatePicker } from "./MyDatePicker";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

interface Bid {
  game_id?: number;
  game_type?: string;
  open_digit: string;
  close_panna: string;
  bid_points: string;
  session: string;
  open_panna?: string;
  close_digit?: string;
}

const HafSangam: React.FC<any> = () => {
  const CLOSING_PANNA = [  100, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 122, 123, 124, 125, 126, 127, 128, 129,
    130, 133, 134, 135, 136, 137, 138, 139, 140, 144, 145, 146, 147, 148, 149, 150, 155, 156, 157, 158, 159,
    160, 166, 167, 168, 169, 170, 177, 178, 179, 180, 188, 189, 190, 199, 200, 220, 222, 223, 224, 225, 226,
    227, 228, 229, 230, 233, 234, 235, 236, 237, 238, 239, 240, 244, 245, 246, 247, 248, 249, 250, 255, 256,
    257, 258, 259, 260, 266, 267, 268, 269, 270, 277, 278, 279, 280, 288, 289, 290, 299, 300, 330, 333, 334,
    335, 336, 337, 338, 339, 340, 344, 345, 346, 347, 348, 349, 350, 355, 356, 357, 358, 359, 360, 366, 367,
    368, 369, 370, 377, 378, 379, 380, 388, 389, 390, 399, 400, 440, 444, 445, 446, 447, 448, 449, 450, 455,
    456, 457, 458, 459, 460, 466, 467, 468, 469, 470, 477, 478, 479, 480, 488, 489, 490, 499, 500, 550, 555,
    556, 557, 558, 559, 560, 566, 567, 568, 569, 570, 577, 578, 579, 580, 588, 589, 590, 599, 600, 660, 666,
    667, 668, 669, 670, 677, 678, 679, 680, 688, 689, 690, 699, 700, 770, 777, 778, 779, 780, 788, 789, 790,
    799, 800, 880, 888, 889, 890, 899, 900, 990, 999 ];

  const location = useLocation();
  const token = localStorage.getItem("token") || "";

  const [bids, setBids] = useState<Bid[]>([]);
  const [suggestedPannas, setSuggestedPannas] = useState<number[]>([]);
  const [suggestedClosePannas, setSuggestedClosePannas] = useState<number[]>([]);
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm<Bid>();

  const onSubmit = (data: Bid) => {
    const defaultValues: Partial<Bid> = {
      game_id: location?.state?.id,
      game_type: "full_sangam"
    };

    const bidData = { ...defaultValues, ...data };

    setBids([...bids, bidData]);
    reset();
  };

  const handleFormSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const submitBid = async () => {
    if (bids.length === 0) {
      toast.error("Please add bids before submitting.");
      return;
    }

    const formData = new FormData();

    // Add default values for missing fields
    const modifiedBids = bids.map(bid => ({
      ...bid,
      open_panna: bid.open_panna || '', 
      close_digit: bid.close_digit || '', 
      open_digit: bid.open_digit || '', 
      close_panna: bid.close_panna || '',
      session:"open"
    }));

    formData.append("game_bids", JSON.stringify({ bids: modifiedBids }));

    try {
      const response = await axios.post(
        "https://development.smapidev.co.in/api/Api/place_bid",
        formData,
        {
          headers: {
            token,
            "Content-Type": "application/x-www-form-urlencoded",
            Cookie: "ci_session=0b0000be09ab15b1746f67a94c05d0d6761be9f3",
          },
        }
      );

      if (response.data.status === "success") {
        toast.success("Bids submitted successfully!");
        setBids([]); // Clear bids after successful submission
      } else {
        toast.error(response.data.message || "Failed to submit bids.");
      }
    } catch (error) {
      toast.error("An error occurred while submitting bids. Please try again.");
    }
  };

  // Custom validation function to check if open panna exists
  const validateOpenPanna = (value: string) => {
    const isValid = CLOSING_PANNA.includes(parseInt(value));
    return isValid || "Enter valid panna.";
  };

  // Function to suggest pannas based on user input
  const suggestPannas = (inputValue: string) => {
    const filteredPannas = CLOSING_PANNA.filter(panna => panna.toString().startsWith(inputValue));
    setSuggestedPannas(filteredPannas);
  };

  // Function to suggest close pannas based on user input
  const suggestClosePannas = (inputValue: string) => {
    const filteredPannas = CLOSING_PANNA.filter(panna => panna.toString().startsWith(inputValue));
    setSuggestedClosePannas(filteredPannas);
  };

  // Function to set the open panna value when a suggestion is selected
  const setOpenPanna = (panna: number) => {
    setValue("open_digit", panna.toString());
    setSuggestedPannas([]);
  };

  // Function to set the close panna value when a suggestion is selected
  const setClosePanna = (panna: number) => {
    setValue("close_panna", panna.toString());
    setSuggestedClosePannas([]);
  };

  return (
    <div className="bg-gray-100 rounded-lg">
      <ToastContainer />
      <NavBar2 isFullSangam={location?.state?.isFullSangam} />
      <div className="container shadow-md w-100 bg-white p-3 rounded-md text-left">
        <div className="flex">
          <MyDatePicker />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          

          <div className="panna-new text-left mt-4">
            <label htmlFor="digit">Open Panna</label>
            <div className="input-group">
              <span className="input-group-text"><FaEdit /></span>
              <input 
                type="text" 
                className="form-control" 
                {...register("open_digit", { required: true, validate: validateOpenPanna })} 
                maxLength={3} 
                placeholder="Enter Open Panna"
                onChange={(e) => suggestPannas(e.target.value)} 
              />
            </div>
            {errors.open_digit && <span className="text-red-500">{errors.open_digit.message}</span>}
            {suggestedPannas.length > 0 && (
              <ul>
                {suggestedPannas.map((panna, index) => (
                  <li className="cursor-pointer" key={index} onClick={() => setOpenPanna(panna)}>{panna}</li>
                ))}
              </ul>
            )}
          </div>

          <div className="panna-new text-left mt-4">
            <label htmlFor="digit">Close Panna</label>
            <div className="input-group">
              <span className="input-group-text"><FaEdit /></span>
              <input 
                type="text" 
                className="form-control" 
                {...register("close_panna", { required: true, validate: validateOpenPanna })} 
                maxLength={3} 
                placeholder="Enter Close Panna"
                onChange={(e) => suggestClosePannas(e.target.value)} 
                
              />
            </div>
            {errors.close_panna && <span className="text-red-500">{errors.close_panna.message}</span>}
            {suggestedClosePannas.length > 0 && (
              <ul>
                {suggestedClosePannas.map((panna, index) => (
                  <li className="cursor-pointer" key={index} onClick={() => setClosePanna(panna)}>{panna}</li>
                ))}
              </ul>
            )}
          </div>

          <div className="panna-new text-left mt-4">
            <label htmlFor="points">Points</label>
            <div className="input-group">
              <span className="input-group-text"><FaRupeeSign /></span>
              <input type="text" className="form-control" {...register("bid_points", { required: true, pattern: /^[0-9]+$/ })} placeholder="Enter Points" />
            </div>
            {errors.bid_points && <span className="text-red-500">Please enter valid points</span>}
          </div>

          <div className="Proceed mt-6">
            <button type="submit" className="btn-proceed bgmatch w-100 text-white py-2 px-4 rounded-lg">Add Bid</button>
          </div>
        </form>

        <div className="mt-6">
          <h2 className="font-bold mb-4">Bids</h2>
          <table className="table">
            <thead>
              <tr>
                <th>Open Panna</th>
                <th>Close Panna</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              {bids.map((bid, index) => (
                <tr key={index}>
                  <td>{bid.open_digit}</td>
                  <td>{bid.close_panna}</td>
                  <td>{bid.bid_points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-4">
          <button className="btn-submit w-100 bgmatch text-white py-2 px-4 rounded-lg" onClick={submitBid}>Submit Bids</button>
        </div>
      </div>
    </div>
  );
};

export default HafSangam;
