import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { NavBar2 } from "../NavBar2";

type DataType = {
  bid_points: string;
  bidded_at: string;
  game_name: string;
  game_type: string;
  close_digit?: string;
  close_panna?: string;
  open_digit?: string;
  open_panna?: string;
  session: string;
  game_id: string;
};

export const BidHistory: React.FC = () => {
  const location = useLocation();
  const token = localStorage.getItem("token") || "";

  // Set today's date as the default value
  const todayDate = new Date().toISOString().split("T")[0];
  const [fromDate, setFromDate] = useState<string>(todayDate);
  const [toDate, setToDate] = useState<string>(todayDate);

  const [historyDetails, setHistoryDetails] = useState<DataType[]>([]);

  const handleFromDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToDate(event.target.value);
  };
  const formatDateForAPI = (date: string): string => {
    const localDate = new Date(date);
    
    // Adjusting the date to local timezone
    const offset = localDate.getTimezoneOffset();
    localDate.setMinutes(localDate.getMinutes() - offset);
    
    // Format as YYYY-MM-DD HH:mm:ss
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(localDate.getDate()).padStart(2, '0');
    
    const hours = String(localDate.getHours()).padStart(2, '0');
    const minutes = String(localDate.getMinutes()).padStart(2, '0');
    const seconds = String(localDate.getSeconds()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  
  const formatDateWithTime = (date: string): string => {
    const localDate = new Date(date);
  
    // Adjusting the date to local timezone
    const offset = localDate.getTimezoneOffset();
    localDate.setMinutes(localDate.getMinutes() - offset);
  
    // Adding one day to include the entire day
    localDate.setDate(localDate.getDate() + 1);
    localDate.setHours(0, 0, 0, 0); // Set time to 00:00:00 of the next day
  
    // Format as YYYY-MM-DD HH:mm:ss
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0');
    const day = String(localDate.getDate()).padStart(2, '0');
    
    const hours = String(localDate.getHours()).padStart(2, '0');
    const minutes = String(localDate.getMinutes()).padStart(2, '0');
    const seconds = String(localDate.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  
  
  
  
  
  

  const handleSubmit = async () => {
    console.log("From Date:", fromDate);
    console.log("To Date:", toDate);
    try {
      const formData = new URLSearchParams();
      formData.append("from_date", formatDateForAPI(fromDate) || "");
      formData.append("to_date", formatDateWithTime(toDate) || "");
  
      let apiUrl = "https://development.smapidev.co.in/api/Api/bid_history";
  
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          token,
          "Content-Type": "application/x-www-form-urlencoded",
          Cookie: "ci_session=d2a8bfc834befa449f25ec1a4d1e4de08c515354",
        },
        body: formData,
      });
  
      const data = await response.json();
      if (data?.code === "100") {
        setHistoryDetails(data.data);
        console.log(data.data);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error("An error occurred");
      console.error("Error during login:", error);
    }
  };
  
  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <>
      <NavBar2 isBidHistory={true} />
      <div className="container mx-auto p-2 rounded-lg text-white mt-4">
        <ToastContainer />
        <div className="login-primary px-4 py-4 rounded-4">
          <div className="flex justify-between">
            <h1 className="text-white">From Date</h1>
            <h1 className="text-white">To Date</h1>
          </div>

          <div className="flex justify-around">
            <input
              type="date"
              value={fromDate}
              onChange={handleFromDateChange}
              className="rounded-lg  py-2 mt-2 pl-2 mb-4 mr-4 bg-gray-200 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-400"
            />

            <input
              type="date"
              value={toDate}
              onChange={handleToDateChange}
              max={todayDate}
              className="rounded-lg py-2 mt-2 pl-2 mb-4 ml-2 bg-gray-200 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
          </div>

          <button
            onClick={handleSubmit}
            className="rounded mt-3 bg-blue-300 px-5 py-2 text-black"
          >
            SEND REQUEST
          </button>
        </div>

        {/* Displaying the bid history */}
        <div className="mt-4">
          {historyDetails.length > 0 ? (
            historyDetails.map((item, index) => (
              <div key={index}>
                <div
                  className="card mb-3"
                  style={{
                    border: "1px solid #d1d9e6",
                    backgroundColor: "#f7faff",
                    borderRadius: "8px",
                    maxWidth: "96%",
                    margin: "auto",
                  }}
                >
                  <div className="card-body p-3">
                    <div className="d-flex justify-content-between">
                      <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                        #{item.game_id}
                      </span>
                      <span
                        style={{
                          color: "green",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        Points : {item.bid_points}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between">
                    <h6
                      className="mt-1"
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        textAlign: "start",
                      }}
                    >
                      {item.game_name} ({item.game_type})
                    </h6>
                    {(item.game_type !== "jodi_digit" && item.game_type !== "full_sangam" && !(item.game_type === "single_panna" && item.session === "closed")) && (
  <p style={{fontSize:"12px"}}>session: {item.session}</p>
)}



                    </div>
                    

                    {item.game_type==="half_sangam" && item.session === "Open" && (
                      <div className=" justify-content-between">
 <p
                        className="mb-1"
                        style={{ fontSize: "12px", textAlign: "start" }}
                      >
                        <strong>Close Panna:</strong> {item.close_panna}
                    
                      </p><p
                        className="mb-1"
                        style={{ fontSize: "12px", textAlign: "start" }}
                      >
                       
                        <strong>Open Digit:</strong> {item.open_digit}
                      </p>
                      </div>
                    )}

{item.game_type==="half_sangam" && item.session === "Close" && (
                      <div className=" justify-content-between">
 <p
                        className="mb-1"
                        style={{ fontSize: "12px", textAlign: "start" }}
                      >
                        <strong>Open Panna:</strong> {item.open_panna}
                    
                      </p><p
                        className="mb-1"
                        style={{ fontSize: "12px", textAlign: "start" }}
                      >
                       
                        <strong>Close Digit:</strong> {item.close_digit}
                      </p>
                      </div>
                    )}
                   
                   {item.game_type==="full_sangam"  && (
                      <div className=" justify-content-between">
 <p
                        className="mb-1"
                        style={{ fontSize: "12px", textAlign: "start" }}
                      >
                        <strong>Open Panna:</strong> {item.open_panna}
                    
                      </p><p
                        className="mb-1"
                        style={{ fontSize: "12px", textAlign: "start" }}
                      >
                       
                        <strong>Close Panna:</strong> {item.close_panna}
                      </p>
                      </div>
                    )}



{item.game_type==="jodi_digit"  && (
                      <div className=" justify-content-between">
 <p
                        className="mb-1"
                        style={{ fontSize: "12px", textAlign: "start" }}
                      >
                        <strong>Jodi Digit:</strong> {item.open_digit}
                    
                      </p>
                      </div>
                    )}

                    
{item.game_type==="double_panna"  && (
                      <div className=" justify-content-between">
 <p
                        className="mb-1"
                        style={{ fontSize: "12px", textAlign: "start" }}
                      >
                        <strong>Panna:</strong> {item.open_digit || item.close_panna}
                    
                      </p>
                      </div>
                    )}

{item.game_type === "single_digit" && (
  <div className="justify-content-between">
    <p
      className="mb-1"
      style={{ fontSize: "12px", textAlign: "start" }}
    >
      <strong>Digit:</strong> 
      {item.close_digit !== "default_value" ? item.close_digit : item.open_digit !== "default_value" ? item.open_digit : ''}
    </p>
  </div>
)}


{item.game_type==="single_panna"  && (
                      <div className=" justify-content-between">
 <p
                        className="mb-1"
                        style={{ fontSize: "14px", textAlign: "start" }}
                      >
                        <strong>Panna:</strong> {item.open_digit}
                    
                      </p>
                      </div>
                    )}

                    
{item.game_type==="triple_panna"  && (
                      <div className=" justify-content-between">
 <p
                        className="mb-1"
                        style={{ fontSize: "14px", textAlign: "start" }}
                      >
                        <strong>Panna:</strong> {item.open_digit || item.open_panna || item.close_panna}
                    
                      </p>
                      </div>
                    )}
                    {/* {item.close_panna && item.close_panna !== "default_value" && (
                       <div className="d-flex justify-content-between">
                      <p
                        className="mb-1"
                        style={{ fontSize: "14px", textAlign: "start" }}
                      >
                        <strong>Panna:</strong> {item.close_panna}
                      </p>
                   
                      </div>
                    )} */}
                    {/* {item.open_digit && item.close_digit === "default_value" && (
                       <div className="d-flex justify-content-between">
                      <p
                        className="mb-1"
                        style={{ fontSize: "14px", textAlign: "start" }}
                      >
                        <strong>Digit:</strong> {item.open_digit}
                      </p>
                     
                      </div>
                    )}

{item.open_digit && item.close_digit !== "default_value" && (
  <div className="d-flex justify-content-between">
    <p className="mb-1" style={{ fontSize: "14px", textAlign: "start" }}>
      <strong>Digit:</strong> {item.open_digit} {item.close_digit}
    </p>
  </div>
)} */}






                    {/* {item.open_panna && item.open_panna !== "default_value" && (
                       <div className="d-flex justify-content-between">
                      <p
                        className="mb-1"
                        style={{ fontSize: "14px", textAlign: "start" }}
                      >
                        <strong>Panna:</strong> {item.open_panna}
                      </p>
                     
                      </div>
                    )} */}

                    {/* Fallback if no valid data */}
                    {!(
                      (item.close_digit && item.close_digit !== "default_value") ||
                      (item.close_panna && item.close_panna !== "default_value") ||
                      (item.open_digit && item.open_digit !== "default_value") ||
                      (item.open_panna && item.open_panna !== "default_value")
                    ) && (
                      <p
                        className="mb-1"
                        style={{ fontSize: "14px", textAlign: "start", color: "red" }}
                      >
                        <strong>No valid data available</strong>
                      </p>
                    )}
                    <p
                      className="mb-0"
                      style={{
                        fontSize: "12px",
                        color: "#6c757d",
                        textAlign: "start",
                      }}
                    >
                      {item.bidded_at}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center mt-4">
              <img
                src="https://img.freepik.com/premium-vector/no-data-concept-illustration_634196-28497.jpg?semt=ais_hybrid"
                alt="No Data"
                style={{ width: "150px", height: "150px", margin: "auto" }}
              />
              <p style={{ color: "#6c757d", marginTop: "10px" }}>No data available</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BidHistory;
