import React, { useState } from "react";
import { FaEdit, FaRupeeSign } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { NavBar2 } from "./NavBar2";
import { MyDatePicker } from "./MyDatePicker";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios';
import { Console } from "console";

interface SingleDigitProps {
  isDoublePanna?: boolean;
}

interface Bid {
  game_id?: number;
  game_type?: string;
  open_digit: string;
  bid_points: string;
  session: string;

    open_panna?: string; // or whatever type this should be
    close_panna?: string; // or whatever type this should be
    close_digit?: string; // or whatever type this should be
 
}

const Jodiigit: React.FC<SingleDigitProps> = ({ isDoublePanna }) => {

  const location = useLocation();
  const Jodidigit=['00','01', '02', '03', '04', '05', '06', '07', '08', '09', '10',
    '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30',
    '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50',
    '51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '70',
    '71', '72', '73', '74', '75', '76', '77', '78', '79', '80', '81', '82', '83', '84', '85', '86', '87', '88', '89', '90',
    '91', '92', '93', '94', '95', '96', '97', '98', '99']
  const token = localStorage.getItem("token") || '';
  const [bids, setBids] = useState<Bid[]>([]);
  const { register, handleSubmit, formState: { errors }, reset, getValues, setValue } = useForm<Bid>();
  const [suggestedPannas, setSuggestedPannas] = useState<number[]>([]);
  const [filteredDigits, setFilteredDigits] = useState<string[]>([]);
  const [selectedDigit, setSelectedDigit] = useState<string>("");
  
  const onDigitInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
  
    // Check if the input matches a valid digit from the array
    if (Jodidigit.includes(input)) {
      setValue("open_digit", input); // Update form value
      setSelectedDigit(input);
      setFilteredDigits([]); // Clear suggestions if it's a direct match
    } else if (input.trim() !== "") {
      // Filter suggestions based on input
      const filtered = Jodidigit.filter((digit) => digit.startsWith(input));
      setFilteredDigits(filtered);
    } else {
      setFilteredDigits([]); // Clear suggestions if input is empty
    }
  };
  
  const handleDigitSelect = (digit: string) => {
    setValue("open_digit", digit); // Update form value
    setSelectedDigit(digit);
    setFilteredDigits([]); // Hide suggestions after selection
  };

  const onSubmit = (data: Bid) => {
    if (!Jodidigit.includes(data.open_digit)) {
      toast.error("Please select a valid Jodi digit from the list.");
      return;
    }
  
    const defaultValues: Partial<Bid> = {
      game_id: location?.state?.id,
      game_type: "jodi_digit",
      session: "open",
    };
  
    const bidData = { ...defaultValues, ...data };
    setBids([...bids, bidData]);
    reset();
    setSelectedDigit(""); // Reset the selected digit
  };

  const handleFormSubmit = () => {
    handleSubmit(onSubmit)();
  };
  const submitBid = async () => {
    if (bids.length === 0) {
      toast.error('Please add bids before submitting.');
      return;
    }
   
  
    const formData = new FormData();
    
    // Add open_panna, close_panna, and close_digit to each bid if required
    const modifiedBids = bids.map(bid => ({
      ...bid,
      open_panna: bid.open_panna ?? 'default_value', // fallback to 'default_value' if undefined
      close_panna: bid.close_panna ?? 'default_value', // fallback to 'default_value' if undefined
      close_digit: bid.close_digit ?? 'default_value'// replace with the appropriate value
    }));
  
    formData.append("game_bids", JSON.stringify({ bids: modifiedBids }));
  
    try {
      console.log('Submitting bids:', modifiedBids); // Log bids to check if they're valid
      
      const response = await axios.post("https://development.smapidev.co.in/api/Api/place_bid", formData, {
        headers: {
          token,
          'Content-Type': 'application/x-www-form-urlencoded',
          'Cookie': 'ci_session=0b0000be09ab15b1746f67a94c05d0d6761be9f3'
        },
      });
  
      console.log('Response:', response.data);
      
      // Check response status to determine success or failure
      if (response.data.status === 'success') {
        toast.success('Bids submitted successfully!');
        setBids([]); // Clear bids after successful submission
      } else {
        toast.error(response.data.message || 'Failed to submit bids.');
      }
  
    } catch (error) {
      toast.error('An error occurred while submitting bids. Please try again.');
      
    }
  };
  
  
  

 

  const setOpenPanna = (panna: number, currentSession: string) => {
    setValue("open_digit", panna.toString());
    setSuggestedPannas([]);
  };

  return (
    <div>
      <ToastContainer />
      <NavBar2 isJodiPanna={location?.state?.isJodiPanna} />
      <div className="container shadow-md w-100 bg-white p-3 rounded-md text-left">
        <div>
          <MyDatePicker />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
            {/* <div className="container text-left">
              <h2 className="choose_session font-bold mb-4">Choose Session</h2>
              <div className="open flex justify-between">
                <label>
                  <input   type="radio" {...register("session", { required: true })} value="open" /> Open
                </label>
                <label>
                  <input type="radio" defaultChecked={true} {...register("session", { required: true })} value="close" /> Close
                </label>
              </div>
              {errors.session && <span className="text-red-500">Please select a session</span>}
            </div> */}
            <div className="panna-new text-left mt-4">
              <label htmlFor="digit">Jodi Digit</label>
              <div className="input-group">
                <span className="input-group-text"><FaEdit /></span>
                <input 
                 type="text"
            className="form-control"
            value={selectedDigit}
            onChange={onDigitInputChange}
             placeholder="Enter Jodi Digit" 
             />
             


          
              </div>
              {filteredDigits.length > 0 && (
              <ul>
                {filteredDigits.map((panna, index) => (
                  <li className="cursor-pointer" key={index} onClick={() => handleDigitSelect(panna)}>{panna}</li>
                ))}
              </ul>
            )}
              {errors.open_digit && <span className="text-red-500">Please enter a jodi digit number</span>}
            </div>
            <div className="panna-new text-left mt-4">
              <label htmlFor="points">Points</label>
              <div className="input-group">
                <span className="input-group-text"><FaRupeeSign /></span>
                <input type="text" className="form-control" {...register("bid_points", { required: true, pattern: /^[0-9]+$/ })} placeholder="Enter Points" />
              </div>
              
              {errors.bid_points && <span className="text-red-500">Please enter valid points</span>}
            </div>
            <div className="Proceed mt-6">
              <button type="submit" className="btn-proceed bgmatch w-100 text-white py-2 px-4 rounded-lg">Add Bid</button>
            </div>       </form>

        <div className="mt-6">
          <h2 className="font-bold mb-4">Bids</h2>
          <table className="table">
            <thead>
              <tr>
               
                <th>Digit</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              {bids.map((bid, index) => (
                <tr key={index}>
                 
                  <td>{bid.open_digit}</td>
                  <td>{bid.bid_points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-4">
          <button className="btn-submit bgmatch text-white py-2 w-100 px-4 rounded-lg" onClick={submitBid}>Proceed</button>
        </div>
      </div>
    </div>
  );
};

export default Jodiigit;



