import { useParams } from 'react-router-dom';

const Chart = () => {
  const { chartLink } = useParams(); // This will extract the 'chartLink' from the URL.

  const imageSrc = decodeURIComponent(chartLink); // Decode the URL-encoded image URL

  console.log(imageSrc); // This should give the correct image URL

  return (
    <div>
      {/* Header */}
      <div style={{ background: "#101A4F", padding: "10px", color: "white", textAlign: "start" }}>
        💹Chart
      </div>

      {/* Image Display */}
      <div style={{ textAlign: "center", marginTop: "" }}>
        <iframe width={"100%"} height={"900"} src={imageSrc} ></iframe>
      </div>
    </div>
  );
};

export default Chart;
