// Carousel.tsx
import React, { useEffect, useState } from "react";
import Carousel from "./carousel";
import {
  FaChartBar,
  FaChartLine,
  FaGamepad,
  FaGlobe,
  FaHandHoldingUsd,
  FaMoneyBillWave,
  FaPhone,
  FaPlay,
  FaPlayCircle,
  FaSpinner,
  FaStreetView,
  FaWhatsapp,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import a from "../images/a.png"
import b from "../images/b.png"
import c from "../images/c.png"

const Home: React.FC = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token") || '';

  const [mainGameList, setMainGameList] = useState([]);
  const [WhatsAppno, serWhatsappno] = useState()
  const [phone, serPhone] = useState()
  const [playLink,serPlayLink] = useState()

  const [loading, setLoading] = useState(false)
  useEffect(() => {
    gameList()
  }, [])


  const gameList = async () => {
    try {
      setLoading(true)
      const response = await fetch("https://development.smapidev.co.in/api/Api/main_game_list",
        {
          method: "POST",
          headers: {
            token
            // 'Content-Type': 'application/x-www-form-urlencoded',
            // 'Cookie': 'ci_session=0b0000be09ab15b1746f67a94c05d0d6761be9f3'
          },
        });

      response.json().then((result: any) => {
        if (result.code == 100) {
          setMainGameList(result?.data)
        }
        setLoading(false)
      }).catch((error: any) => {
        alert(error)
        setLoading(false)

      })

    } catch (error) {
      toast.error("Internal server error ")
      // alert("ERROR IN GAME LIST" + error)
    }
  }

  const fetchAppDetails = async () => {
    try {
      const response = await axios.post(
        'https://development.smapidev.co.in/api/Api/app_details',
        '', // Sending a blank string in the body as specified
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      serWhatsappno(response.data.data.contact_details.whatsapp_no)
      serPhone(response.data.data.contact_details.mobile_no_1)
      serPlayLink(response.data.data.play_link)
      console.log(response.data)
    } catch (error) {
      console.error('Error fetching app details:', error);
    }
  };


  useEffect(() => {
    fetchAppDetails()
  }, [])



  return (
    // <>
    <div>
      {/* <Carousel /> */}

      <div className="container primary-text">
        <div className="flex flex-row gap-3">

          {/* Phone Button */}
          <button 
            onClick={() => {
              window.open(`tel:${phone}`, '_blank');

            }}
            className="bg-white hover:bg-black-600 text-black font-medium py-2 px-2 rounded"
          > 
       
            <FaPhone className="fs-6" />
            <span>Call Us</span>
           

          </button>

          {/* WhatsApp Button */}
          <button
            className="custom-green text-white font-medium py-2 px-2 rounded"
            onClick={() => {
              window.location.href = `https://wa.me/+91${WhatsAppno}`;
            }}
          >
            <FaWhatsapp className="fs-6" />
            WhatsApp
          </button>


          

          {/* How to Play Button */}
          <button className="custom-purple text-white font-medium py-2 px-2 rounded" onClick={() => { navigate("/help") }}>
            <FaPlay className="mr-2" />
            How to Play
          </button>

          
        </div>

        {/* Fund Buttons */}
        <div className="flex flex-row md:flex-row gap-3 md:space-x-4 mt-4">

     
          {/* Add Fund Button */}
          <div
          style={{ cursor: "pointer", fontSize: "12px" }}
            onClick={() => {
              navigate("/funds");
            }}
            className="custom-blue text-white text-sm font-medium flex flex-fill justify-content-center items-center py-2 px-2 rounded"
          >
            <FaMoneyBillWave size="30" /> Add Points
          </div>



          {/* <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/chart");
            }}
            className="bg-white text-black  text-sm font-medium flex flex-fill justify-content-center items-center py-2 px-2 rounded"
          >
            <FaChartBar size="fs-20" color="green" /> 
           chart
          </div> */}

        
          {/* Withdraw Fund Button */}
          <div
          style={{ cursor: "pointer", fontSize: "12px" }}
            onClick={() => {
              navigate("/withdraw");
            }}
            className="bg-red-600 text-white  flex flex-fill justify-content-center items-center py-2 px-2 rounded"
          >
            <FaHandHoldingUsd size={30} /> Withdraw
          </div>
          
        </div>

        {/* Secondary Buttons */}
        <div className="flex flex-row md:flex-row gap-3 md:space-x-4 mt-4">
          {/* Play Star Line Button */}
          <button className="bg-white hover:bg-yellow-600 text-black font-medium py-2 px-4 rounded" onClick={() => { navigate("/starline", { state: { startLine: true } }) }}>
            <FaPlayCircle size={20} />
            Star Line
          </button>

          {/* Website Button */}
          <button  className="bg-white hover:bg-indigo-600 text-black font-medium py-2 px-4 rounded"
          onClick={() => {
            window.location.href = `${playLink}`;
          }}>
            <FaGlobe size={20} />
            Apk
          </button>

          {/* Gali Desawar Button */}
          <button className="bg-white hover:bg-red-600 text-black font-medium py-2 px-4 rounded" onClick={() => { navigate("/starline", { state: { galiDesawar: true } }) }}>
            <FaStreetView size={20} />
            Gali Desawar
          </button>
        </div>
      </div>


      <div className="mx-auto rounded-xl overflow-hidden satta-matka-home1">
        {
          loading ? (
            <FaSpinner />
          ) : (
            <div className="satta-matka">
              <div className="md:flex-shrink-0"></div>
              <div className="card-bg">
                {
                  mainGameList.length > 0 ? (
                    <>
                      {
                        mainGameList.map((game: any) => (
                          <div className="card-new p-2">
                            <div className="uppercase heading-block tracking-wide text-xl font-bold">
                              {game.name}
                            </div>
                            <div className="flex align-items-center justify-around">
                             
                                <div className="  " style={{fontSize:"12px", padding:"10px", paddingLeft:"25px", paddingRight:"25px"}}>
                                    {/* <Link className="rounded-5" to="/madhurnight" > Play Now
                                  </Link> */}
                                 
                                 <Link
    to={`/chart/${encodeURIComponent(game.chart_url)}`}
  >
    <img src={c} alt="chart" width={"50px"} />
  </Link>
                                  </div>

                             
                              <div className="flex">
                                <a
                                  href="#"
                                  className="block mt-1 text-lg leading-tight font-medium text-gray-500 hover:underline"
                                >
                                  {/* 569-900-668 */}
                                  {
                                    game.result
                                  }
                                </a>
                              </div>
                              {
                                game.play ? (
                                  <div className=" " style={{fontSize:"12px"}}>
                                    {/* <Link className="rounded-5" to="/madhurnight" > Play Now
                                  </Link> */}
                                    <button onClick={() => {
                                      navigate('/madhurnight', {
                                        state: {
                                          open: game.open,
                                          id: game.id,
                                          name:game.name
                                        }
                                      });
                                    }}> <img src={a} alt="chart" width={"50px"} /></button>
                                  </div>
                                ) : (
                                  <div className="flex">
                                    <a className=" rounded-5" href="#">
                                    <img src={b} alt="chart" width={"50px"} />
                                    </a>
                                  </div>
                                )
                              }
                            </div>
                            <p className="mt-2 text-black">
                              Open : {game.open_time}
                              {
                                game.open_time === Date.now() ? (
                                  <b className="text-green-600"></b>
                                ) : null
                              }

                              <span>Closes: {game.close_time}</span>
                            </p>
                          </div>
                        ))
                      }
                    </>
                  ) : (
                    <p>No data found</p>
                  )
                }



              </div>
            </div>
          )
        }

      </div>
    </div>
    // </>
  );
};

export default Home;
