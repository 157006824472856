import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NavBar2 } from "./NavBar2";
import { Button } from "@mui/material";
import a from "../images/a.png"
import b from "../images/b.png"
import c from "../images/c.png"

interface StarLineGame {
  name: string;
  id: string;
  result: string;
  time: string;
  play: boolean;
}

interface Rate {
  name: string;
  cost_amount: string;
  earning_amount: string;
}

const StarLine: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [mainGameList, setMainGameList] = useState<StarLineGame[]>([]);
  const [startLineRates, setStarLineRates] = useState<Rate[]>([]);
  const [chartUrl, setChartUrl] = useState<string>("");

  const token = localStorage.getItem("token") || '';

  useEffect(() => {
    gameList();
  }, []);

  const gameList = async () => {
    try {
      let url = "https://development.smapidev.co.in/api/Api/gali_disawar_game";
      if (location?.state?.startLine) {
        url = "https://development.smapidev.co.in/api/Api/starline_game";
      }

      console.log("Fetching from URL:", url);

      const response = await axios.post(url, {}, {
        headers: {
          token
        }
      });

      const result = response.data;

      setMainGameList(location?.state?.startLine ? result?.data?.starline_game : result?.data?.gali_disawar_game);
      setStarLineRates(location?.state?.startLine ? result?.data?.starline_rates : result?.data?.gali_disawar_rates);

      // Set the chart URL for both games
      if (location?.state?.startLine) {
        setChartUrl(result?.data?.starline_chart || "");
      } else {
        setChartUrl(result?.data?.gali_disawar_chart || "");
      }

    } catch (error) {
      const errorMessage = (error as Error).message; // Type assertion to Error
      console.error("Error fetching game list:", errorMessage);
      alert("ERROR IN GAME LIST: " + errorMessage);
    }
  };

  return (
    <>
      <NavBar2 isStarLine={!!location?.state?.startLine} isGaliDeswar={!location?.state?.startLine} />
      <div className="container mx-auto p-4 max-w-md rounded-sm shadow-md login-primary text-white mt-4 rounded">
        <h1 className="text-lg font-bold">Games Rate</h1>
        {startLineRates.length > 0 ? (
          startLineRates.map((rate) => (
            <div className="flex justify-content-between mb-3" key={rate.name}>
              <p>{rate.name.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</p>
              <p>{rate.cost_amount} - {rate.earning_amount}</p>
            </div>
          ))
        ) : (
          <>No data</>
        )}
      </div>
      <div className="mt-6">
        <button
          className="mr-2 px-4 py-2 login-primary text-white rounded"
          onClick={() => {
            navigate(location?.state?.startLine ? "/bidhistorystar" : "/bidhistorydisawar");
          }}
        >
          BID HISTORY
        </button>
        <button
          className="mr-2 px-4 py-2 login-primary text-white rounded"
          onClick={() => {
            navigate(location?.state?.startLine ? "/winhistorystar" : "/winhistorydisawar");
          }}
        >
          WIN HISTORY
        </button>
      </div>
      <div className="max-w-md mx-auto rounded-xl overflow-hidden satta-matka-home mt-4">
        <div className="satta-matka">
          <div className="md:flex-shrink-0"></div>
          <div className="card-bg">
            {mainGameList.length > 0 ? (
              mainGameList.map((game) => (
                <div className="card-new p-2" key={game.id}>
                  <div className="uppercase heading-block text-custom tracking-wide text-xl font-bold">
                    {game.name}
                  </div>
                  <div>
                    <div>
                      <span className="btn-card-closed rounded-5 mt-3">{game.time}</span>
                      <div className="flex justify-content-between" >
                        <Link style={{marginTop:"-20px"}}
                          // className={`rounded-5 ${game.play ? "btn-card" : "btn-card-closed"}`}
                          to={game.play ? (location?.state?.startLine ? `/starlanplay/${game.id}/${game.name}` : `/desawerplay/${game.id}/${game.name}`) : "#"}
                        >
                          {game.play ? <img src={a} alt="chart" width={"50px"} /> :  <img src={b} alt="chart" width={"50px"} />}
                        </Link>
                        <Link to="#" className="block mt-1 text-lg leading-tight font-medium text-custom hover:underline mt-3">
                          {game.result}
                        </Link>

                        <span style={{marginTop:"-20px"}} className={`text-${game.play ? 'green' : 'red'}-500 flex items-center mr-2`}>
                          {game.play ? 'Running' : 'Closed'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <>No data</>
            )}
          </div>
        </div>
      </div>

      {chartUrl && (
        <Link
          to={`/chart/${encodeURIComponent(chartUrl)}`}
          className="mr-2 px-4 py-2 mt-3 flex justify-center login-primary text-white rounded w-100"
        >
          Chart
        </Link>
      )}
    </>
  );
};

export default StarLine;
